/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag">
      <div :class="{ 'cursor-drag': draggingCard === true }" class="vx-row">
        <div class="vx-col lg:w-4/12 md:w-4/12 sm:w-5/5">
          <vx-card class="mb-base">
            <h3 class="font-bold mb-4">Device List</h3>
            <div v-for="item of ip_info" class="vx-row" :key="item.id">
              <div class="flex items-center justify-center vx-col w-3/12 p-3">
                <font-awesome-icon
                  icon="fa-sharp fa-solid fa-server"
                  style="background: #181d2a !important"
                  class="h-8 w-8 p-4 rounded-full"
                />
              </div>
              <div class="vx-col w-9/12 p-3">
                <span class="flex flex-row gap-x-2 text-xl font-bold my-1 ubuntu-font"
                  >IP:
                  <p class="font-medium">{{ item.ip }}</p></span
                >
                <vue-draggable-container
                  :id="'input' + item.id"
                  class="answerStandard dropzone dropzoneStandard answer-bg w-full"
                ></vue-draggable-container>
              </div>
            </div>
          </vx-card>
        </div>
        <div class="vx-col lg:w-8/12 md:w-8/12 sm:w-5/5">
          <vue-draggable-container
            id="wordBank"
            class="mb-4 p-4 bg-background wordBank rounded-lg"
            style="border: 1px solid #181d2a; height: fit-content !important"
          >
            <h5 class="dragBlock font-semibold cursor-pointer text-center h-fit">Network Printer</h5>
            <h5 class="dragBlock font-semibold cursor-pointer text-center h-fit">Cisco Router</h5>
            <h5 class="dragBlock font-semibold cursor-pointer text-center h-fit">Windows Workstation</h5>
            <h5 class="dragBlock font-semibold cursor-pointer text-center h-fit">macOS Laptop</h5>
            <h5 class="dragBlock font-semibold cursor-pointer text-center h-fit">Linux Server</h5>
          </vue-draggable-container>

          <vue-command
            id="command_prompt"
            :commands="commands"
            :prompt="'C:\\Users\\' + activeUserInfo.firstName.toLowerCase().replace(/ /g, '') + '>'"
            :show-intro="true"
            class=""
            intro="Microsoft Windows [Version 10.0.18363.1256] (c) 2019 Microsoft Corporation. All rights reserved."
            style="background-color: #111111; border: 1px solid #181d2a; border-radius: 0.5rem"
            title="Command Prompt"
          />
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';
import VueCommand, { createStdout, createStderr } from 'vue-command';
import 'vue-command/dist/vue-command.css';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },

      ip_info: [
        {
          id: '0',
          ip: '192.168.1.1',
        },
        {
          id: '1',
          ip: '192.168.1.13',
        },
        {
          id: '2',
          ip: '192.168.1.27',
        },
        {
          id: '3',
          ip: '192.168.1.31',
        },
        {
          id: '4',
          ip: '192.168.1.44',
        },
      ],

      commands: {
        help: () => createStdout('Available commands:<br>&nbsp;nmap [args] [target]<br>'),
        nmap(x) {
          if (!x.s || !x.V) {
            return createStderr('Invalid Usage: nmap [args] [target]');
          }

          if (x.V === '192.168.1.1') {
            return createStdout(`<br><br><br>-----------------------------------------------<br><br>Starting Nmap 7.91 ( https://nmap.org ) at 2021-09-23 09:40 EDT<br>
Nmap scan report for router (192.168.1.1)<br>
Host is up (0.0050s latency).<br>
Not shown: 998 closed ports<br>
PORT   STATE SERVICE VERSION<br>
23/tcp open  telnet  Cisco router or switch<br>
MAC Address: 12:34:56:78:9A:BC (Manufacturer: Example Cisco)<br><br>

Nmap done: 1 IP address (1 host up) scanned in 10.36 seconds<br><br>
  -----------------------------------------------<br><br><br>`);
          }

          if (x.V === '192.168.1.13') {
            return createStdout(`<br><br><br>-----------------------------------------------<br><br>Starting Nmap 7.91 ( https://nmap.org ) at 2021-09-23 09:00 EDT<br>
Nmap scan report for linux-server (192.168.1.10)<br>
Host is up (0.0050s latency).<br>
Not shown: 998 closed ports<br>
PORT   STATE SERVICE VERSION<br>
22/tcp open  ssh     OpenSSH 7.6p1 Ubuntu 4ubuntu0.5 (Ubuntu Linux; protocol 2.0)<br>
MAC Address: 00:11:22:33:44:55 (Manufacturer: Example Linux)<br><br>

Nmap done: 1 IP address (1 host up) scanned in 10.21 seconds<br><br>
  -----------------------------------------------<br><br><br>`);
          }

          if (x.V === '192.168.1.27') {
            return createStdout(`<br><br><br>-----------------------------------------------<br><br>Starting Nmap 7.91 ( https://nmap.org ) at 2021-09-23 09:10 EDT<br>
Nmap scan report for windows-workstation (192.168.1.27)<br>
Host is up (0.0030s latency).<br>
Not shown: 998 closed ports<br>
PORT    STATE SERVICE       VERSION<br>
135/tcp open  msrpc         Microsoft Windows RPC<br>
MAC Address: 00:AA:BB:CC:DD:EE (Manufacturer: Example Windows)<br><br>

Nmap done: 1 IP address (1 host up) scanned in 8.49 seconds<br><br>
  -----------------------------------------------<br><br><br>`);
          }

          if (x.V === '192.168.1.31') {
            return createStdout(`<br><br><br>-----------------------------------------------<br><br>Starting Nmap 7.91 ( https://nmap.org ) at 2021-09-23 09:20 EDT<br>
Nmap scan report for printer (192.168.1.31)<br>
Host is up (0.0070s latency).<br>
Not shown: 998 closed ports<br>
PORT    STATE SERVICE  VERSION<br>
9100/tcp open  jetdirect HP Jetdirect embedded web server ews<br><br>

Nmap done: 1 IP address (1 host up) scanned in 7.64 seconds<br><br>
  -----------------------------------------------<br><br><br>`);
          }

          if (x.V === '192.168.1.44') {
            return createStdout(`<br><br><br>-----------------------------------------------<br><br>Starting Nmap 7.91 ( https://nmap.org ) at 2021-09-23 09:30 EDT<br>
Nmap scan report for macbook (192.168.1.44)<br>
Host is up (0.0090s latency).<br>
Not shown: 998 closed ports<br>
PORT   STATE SERVICE VERSION<br>
80/tcp open  http    nginx 1.21.0<br>
MAC Address: 11:22:33:44:55:66 (Manufacturer: Example macOS)<br><br>

Nmap done: 1 IP address (1 host up) scanned in 9.78 seconds<br><br>
  -----------------------------------------------<br><br><br>`);
          }

          return createStdout('Invalid IP');
        },
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    markExercise() {
      let totalScore = 0;

      if (document.getElementById('input0').textContent === 'Cisco Router') {
        document.getElementById('input0').style.borderColor = '#28C76F';
        document.getElementById('input0').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input1').textContent === 'Linux Server') {
        document.getElementById('input1').style.borderColor = '#28C76F';
        document.getElementById('input1').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input2').textContent === 'Windows Workstation') {
        document.getElementById('input2').style.borderColor = '#28C76F';
        document.getElementById('input2').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input3').textContent === 'Network Printer') {
        document.getElementById('input3').style.borderColor = '#28C76F';
        document.getElementById('input3').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input3').style.borderColor = '#fd4445';
        document.getElementById('input3').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input4').textContent === 'macOS Laptop') {
        document.getElementById('input4').style.borderColor = '#28C76F';
        document.getElementById('input4').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input4').style.borderColor = '#fd4445';
        document.getElementById('input4').style.backgroundColor = '#fd4445';
      }

      if (totalScore === 5) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_exercise_getting_there', totalScore);
    },
  },
  components: {
    draggable,
    Prism,
    VueCommand,
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
